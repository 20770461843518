<template>
  <v-app>
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="accent"
          icon="mdi-source-branch"
          inline
          class="px-5 py-3 mt-7"
          style="width: 100%"
        >
          <v-expansion-panels class="mb-6">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <template v-slot:default="{ open }">
                  <v-row no-gutters>
                    <v-col cols="4">
                      <v-icon
                        x-large
                      >
                        mdi-chart-box-outline
                      </v-icon>
                      <span
                        class="ml-3 mt-1"
                        style="font-weight: bold"
                      >
                        Domain Summary Reports
                      </span>
                    </v-col>
                    <v-col
                      cols="8"
                      class="text-secondary mt-3"
                    >
                      <v-fade-transition leave-absolute>
                        <span
                          v-if="open"
                          key="0"
                        >
                          Domain summary reports
                        </span>
                        <span v-else />
                      </v-fade-transition>
                    </v-col>
                  </v-row>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="4">
                    <v-select
                      v-model="selectedDays"
                      :items="days"
                      density="compact"
                      label="Days"
                      @change="GetDomainStatsLastXDays"
                    />
                  </v-col>
                  <v-spacer />
                  <v-col cols="4">
                    <v-select
                      v-model="selectedMetric"
                      :items="metrics"
                      chips
                      color="primary"
                      label="Metrics"
                    />
                  </v-col>
                </v-row>
                <v-row
                  class="mb-5"
                  justify="center"
                  align="center"
                >
                  <line-chart
                    v-if="domainResults.length !== 0 && selectedMetric !== ''"
                    :chart-data="chartData"
                    :height="400"
                    :width="1000"
                    @item-expanded="updateChart()"
                  />
                </v-row>
                <v-divider v-if="domainResults.length !== 0 && selectedMetric !== ''" />
                <v-row class="mt-5">
                  <v-col cols="12">
                    <v-data-table
                      v-if="domainResults.length !== 0 && selectedMetric !== ''"
                      :headers="headers"
                      :items="domainResults"
                      :items-per-page="5"
                      class="elevation-1"
                    >
                      <template v-slot:[`item.theDate`]="{ item }">
                        <div>
                          <span>{{ item.theDate | displayDateFormat }}</span>
                        </div>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-expansion-panels class="mb-6">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <template v-slot:default="{ open }">
                  <v-row no-gutters>
                    <v-col cols="4">
                      <v-icon
                        x-large
                      >
                        mdi-table-large
                      </v-icon>
                      <span
                        class="ml-3 mt-1"
                        style="font-weight: bold"
                      >
                        Domain summary
                      </span>
                    </v-col>
                    <v-col
                      cols="8"
                      class="text-secondary mt-3"
                    >
                      <v-fade-transition leave-absolute>
                        <span
                          v-if="open"
                          key="0"
                        >
                          List of stats by domain
                        </span>
                        <span v-else />
                      </v-fade-transition>
                    </v-col>
                  </v-row>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row
                  class="mb-5 mr-3"
                  justify="end"
                >
                  <v-btn
                    icon
                    large
                    color="success"
                    @click="GetSendingDomainOverview()"
                  >
                    <v-icon>mdi-cached</v-icon>
                  </v-btn>
                </v-row>
                <v-data-table
                  :headers="summaryHeaders"
                  :items="summaryResults"
                  :items-per-page="10"
                  class="elevation-2"
                >
                  <template v-slot:[`item.lastCampaignSend`]="{ item }">
                    <div>
                      <span>{{ item.lastCampaignSend | displayDateTimeFormat }}</span>
                    </div>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </base-material-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import {
  inputDateFormat,
  displayDateFormat,
  displayTimeFormat,
  displayDateTimeFormat,
  purpleRedGradientDark16,
  greenGreyGradientLight8
} from '@/shared/constants'
import { nowwService } from '@/shared/services'
import moment from 'moment'

export default {
  name: 'DomainManagementSummary',

  components: {
    LineChart: () => import('@/views/pages/components/charts/LineChart')
  },

  data () {
    return {
      loading: false,
      dialog: false,
      expanded: [],
      summaryHeaders: [
        {
          text: 'Sending Domain',
          align: 'start',
          sortable: false,
          value: 'sendingDomain'
        },
        { text: 'Domain Source', align: 'center', value: 'domainSource' },
        { text: 'Source Name', align: 'center', value: 'sourceName' },
        { text: 'Domain Type', align: 'center', value: 'domainType' },
        { text: 'Domain Type Desc', align: 'center', value: 'domainTypeDesc' },
        { text: 'Domain Status', align: 'center', value: 'domainStatus' },
        { text: 'Status Of Domain', align: 'center', value: 'statusOfDomain' },
        { text: 'Last Send', value: 'lastCampaignSend' },
        { text: 'Assigned Customers', align: 'center', value: 'assignedCustomers' },
        { text: '', align: 'start', value: 'data-table-expand' }
      ],
      headers: [
        {
          text: 'Domain',
          align: 'start',
          value: 'sendingDomain'
        },
        { text: 'Sent', value: 'sent', align: 'center' },
        { text: 'Opens', value: 'opens', align: 'center' },
        { text: 'Clicks', value: 'clicks', align: 'center' },
        { text: 'Bounces', value: 'bounces', align: 'center' },
        { text: 'Unsub', value: 'unSub', align: 'center' },
        { text: 'Spam Report', value: 'spamRpt', align: 'center' },
        { text: 'Blocks', value: 'blocks', align: 'center' },
        { text: 'Date', value: 'theDate', align: 'center' }
      ],
      days: [7, 10, 20, 30],
      domainResults: [],
      selectedDays: '',
      metrics: ['opens', 'clicks', 'sent', 'bounces', 'unsubscribes', 'spam report', 'blocks'],
      selectedMetric: '',
      lineChartData: { // Use a different property name to avoid conflict
        labels: [],
        datasets: []
      },
      summaryResults: []
    }
  },

  computed: {
    chartData: {
      get () {
        return this.lineChartData
      },
      set (value) {
        // Dummy setter, you can leave it empty
      }
    }
  },

  watch: {
    selectedMetric (newValue, oldValue) {
      this.updateChart()
    }
  },

  created () {
    this.GetSendingDomainOverview()
  },

  methods: {
    async GetDomainStatsLastXDays () {
      await nowwService.GetDomainStatsLastXDays(this.selectedDays)
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            this.domainResults = resp
            this.updateChart()
          }
        })
        .finally(() => {
          this.resultsLoading = false
        })
    },
    async GetSendingDomainOverview () {
      await nowwService.GetSendingDomainOverview()
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            this.summaryResults = resp
          }
        })
        .finally(() => {
          this.resultsLoading = false
        })
    },
    updateChart () {
      const sendingDomains = [...new Set(this.domainResults.map(item => item.sendingDomain))]
      // Extract unique dates from the dataset
      const uniqueDates = [...new Set(this.domainResults.map(item => item.theDate))]

      // Create a template object with zero values for each metric
      const zeroValuesTemplate = {
        sent: 0,
        opens: 0,
        clicks: 0,
        bounces: 0,
        unSub: 0,
        spamRpt: 0,
        blocks: 0
      }

      // Create a new dataset with zero values for missing dates
      const filledDataset = sendingDomains.map(domain => {
        const domainData = this.domainResults.filter(item => item.sendingDomain === domain)
        const datesWithData = domainData.map(item => item.theDate)

        const missingDates = uniqueDates.filter(date => !datesWithData.includes(date))
        const zeroValuesForMissingDates = missingDates.map(date => ({
          sendingDomain: domain,
          theDate: date,
          ...zeroValuesTemplate
        }))

        return [...domainData, ...zeroValuesForMissingDates]
      })

      // Flatten the filled dataset
      const flattenedDataset = filledDataset.flat()

      this.lineChartData = {
        labels: uniqueDates.map(date => moment(date).format(displayDateFormat)),
        datasets: sendingDomains.map(domain => ({
          label: domain,
          data: flattenedDataset
            .filter(item => item.sendingDomain === domain)
            .map(item => item[this.selectedMetric]),
          borderColor: this.getRandomColor(),
          fill: false
        }))
      }
    },
    getRandomColor () {
      // Function to generate random colors
      return `#${Math.floor(Math.random() * 16777215).toString(16)}`
    }
  }
}
</script>
