var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "base-material-card",
                {
                  staticClass: "px-5 py-3 mt-7",
                  staticStyle: { width: "100%" },
                  attrs: {
                    color: "accent",
                    icon: "mdi-source-branch",
                    inline: ""
                  }
                },
                [
                  _c(
                    "v-expansion-panels",
                    { staticClass: "mb-6" },
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", {
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var open = ref.open
                                  return [
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "4" } },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { "x-large": "" } },
                                              [
                                                _vm._v(
                                                  "\n                      mdi-chart-box-outline\n                    "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "ml-3 mt-1",
                                                staticStyle: {
                                                  "font-weight": "bold"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      Domain Summary Reports\n                    "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "text-secondary mt-3",
                                            attrs: { cols: "8" }
                                          },
                                          [
                                            _c(
                                              "v-fade-transition",
                                              {
                                                attrs: { "leave-absolute": "" }
                                              },
                                              [
                                                open
                                                  ? _c("span", { key: "0" }, [
                                                      _vm._v(
                                                        "\n                        Domain summary reports\n                      "
                                                      )
                                                    ])
                                                  : _c("span")
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.days,
                                          density: "compact",
                                          label: "Days"
                                        },
                                        on: {
                                          change: _vm.GetDomainStatsLastXDays
                                        },
                                        model: {
                                          value: _vm.selectedDays,
                                          callback: function($$v) {
                                            _vm.selectedDays = $$v
                                          },
                                          expression: "selectedDays"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("v-spacer"),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.metrics,
                                          chips: "",
                                          color: "primary",
                                          label: "Metrics"
                                        },
                                        model: {
                                          value: _vm.selectedMetric,
                                          callback: function($$v) {
                                            _vm.selectedMetric = $$v
                                          },
                                          expression: "selectedMetric"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mb-5",
                                  attrs: { justify: "center", align: "center" }
                                },
                                [
                                  _vm.domainResults.length !== 0 &&
                                  _vm.selectedMetric !== ""
                                    ? _c("line-chart", {
                                        attrs: {
                                          "chart-data": _vm.chartData,
                                          height: 400,
                                          width: 1000
                                        },
                                        on: {
                                          "item-expanded": function($event) {
                                            return _vm.updateChart()
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm.domainResults.length !== 0 &&
                              _vm.selectedMetric !== ""
                                ? _c("v-divider")
                                : _vm._e(),
                              _c(
                                "v-row",
                                { staticClass: "mt-5" },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _vm.domainResults.length !== 0 &&
                                      _vm.selectedMetric !== ""
                                        ? _c("v-data-table", {
                                            staticClass: "elevation-1",
                                            attrs: {
                                              headers: _vm.headers,
                                              items: _vm.domainResults,
                                              "items-per-page": 5
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item.theDate",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c("div", [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm._f(
                                                                "displayDateFormat"
                                                              )(item.theDate)
                                                            )
                                                          )
                                                        ])
                                                      ])
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panels",
                    { staticClass: "mb-6" },
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c("v-expansion-panel-header", {
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var open = ref.open
                                  return [
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "4" } },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { "x-large": "" } },
                                              [
                                                _vm._v(
                                                  "\n                      mdi-table-large\n                    "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "ml-3 mt-1",
                                                staticStyle: {
                                                  "font-weight": "bold"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      Domain summary\n                    "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "text-secondary mt-3",
                                            attrs: { cols: "8" }
                                          },
                                          [
                                            _c(
                                              "v-fade-transition",
                                              {
                                                attrs: { "leave-absolute": "" }
                                              },
                                              [
                                                open
                                                  ? _c("span", { key: "0" }, [
                                                      _vm._v(
                                                        "\n                        List of stats by domain\n                      "
                                                      )
                                                    ])
                                                  : _c("span")
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "mb-5 mr-3",
                                  attrs: { justify: "end" }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        large: "",
                                        color: "success"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.GetSendingDomainOverview()
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-cached")])],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-data-table", {
                                staticClass: "elevation-2",
                                attrs: {
                                  headers: _vm.summaryHeaders,
                                  items: _vm.summaryResults,
                                  "items-per-page": 10
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item.lastCampaignSend",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c("div", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f(
                                                    "displayDateTimeFormat"
                                                  )(item.lastCampaignSend)
                                                )
                                              )
                                            ])
                                          ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }